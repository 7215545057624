<template>
  <a-modal v-model="modalVisible" title="选择报事报修工单" :width="900" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
<!--        <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">-->
<!--          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 45%">
          <a-range-picker v-model="queryInstallDate" style="width: 240px"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="contacts" style="width: 30%">
          <a-input v-model="queryParams.contacts"></a-input>
        </a-form-model-item>
<!--        <a-form-model-item label="联系人电话" prop="contact_information" style="width: 25%">-->
<!--          <a-input v-model="queryParams.contact_information" style="width: 120px"></a-input>-->
<!--        </a-form-model-item>-->
        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="order_id" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
          <span slot="need_finish_time" slot-scope="value,record">
            <a-tooltip :title="record.changed_time?moment(record.changed_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD'):(value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : '')">
              <span>{{record.changed_time?moment(record.changed_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD'):(value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : '')}}</span>
            </a-tooltip>
          </span>
        <span slot="order_type" slot-scope="value">
            <span :title="value==0?'报事':'报修'">{{value==0?'报事':'报修'}}</span>
          </span>
        <span slot="order_status" slot-scope="value">
            <span :title="value==3?'拆分':value==2?'异常':'正常'">{{value==3?'拆分':value==2?'异常':'正常'}}</span>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getRepairsOrderListByCondition} from "A/repairsreport";
import moment from "moment";
import {getTimeRange} from "U/index";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    },
    formData:{
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      monitortypes: [],
      queryInstallDate:null,
      queryParams: {
        monitorpointnum: '',
        monitorpointname: '',
        order_type:null,
        contacts: '',
        contact_information: '',
        planstarttime: '',
        planendtime: '',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '报事单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '报事来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '报事类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          key: 'contacts',
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contact_information',
          key: 'contact_information',
          ellipsis: true,
        },
        {
          title: '报事内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '计划完成日期',
          dataIndex: 'need_finish_time',
          key: 'need_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'need_finish_time' },
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.order_id);
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.planstarttime = start;
      this.queryParams.planendtime = end;
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData();
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
      this.queryInstallDate=null
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getRepairsOrderListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.order_id) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.order_id!=record.order_id)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.order_id) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.order_id!=record.order_id)
        })
      }
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>