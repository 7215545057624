<template>
  <page-container :title="pageTitle">
    <div class="box-container">
      <div class="box-container-inner" style="margin: -20px -30px 0px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="事件类型" prop="eventcode" style="width: 25%">
              <a-select v-model="queryParams.eventcode">
                <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="重大影响" prop="biginfluence" style="width: 25%">
              <a-select v-model="queryParams.biginfluence">
                <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="管理类型" prop="managetype" style="width: 25%">
<!--              <a-select v-model="queryParams.managetype">-->
<!--                <a-select-option v-for="(item, index) in manageTypes" :key="index" :value="item.value">{{item.value}}</a-select-option>-->
<!--              </a-select>-->
              <a-input v-model="queryParams.managetype"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            <a-form-model-item label="记录时间" prop="eventstarttime" style="width: 25%">
              <a-date-picker v-model="queryParams.eventstarttime" placeholder="请选择" style="width: 100%"></a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 25%">
              <a-input v-model.trim="queryParams.repairorder" placeholder="请输入"></a-input>
            </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
      <div class="box-container-inner" style="margin: 0px -30px 0px -30px">
        <div class="table-header">
          <h3 class="table-title">{{"异常跟进处理"}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500}" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="plannedwork" slot-scope="value">
            <span>{{value==0?'否':'是'}}</span>
          </span>
          <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'edit-'+record.eventrecordid">跟进</a-menu-item>
                <a-menu-item :key="'delete-'+record.eventrecordid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <abnormal-add-or-edit-modal :visible.sync="abnormalModalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></abnormal-add-or-edit-modal>
    </div>
  </page-container>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import {deleteEventrecord, getEventrecordListByCondition} from "A/wlw";
import abnormalAddOrEditModal from "V/patrols/patrolsRecord/abnormalRecord/addOrEditModal";
import {
  eventstatus,
  eventstatusColors,
  finishSigns,
} from "@/json/wlwhistory";

export default {
  name: "abnormal",
  mixins: [pagination],
  components:{
    abnormalAddOrEditModal
  },
  data() {
    return {
      moment,
      showAdvanced:false,
      finishSigns,
      feedbackList:[],
      abnormalTypes:[],
      bigInfluences:[],
      queryParams:{
        managetype:'',
        biginfluence:'',
        eventcode:'',
        eventstarttime:null,
        repairorder:''
      },
      eventstatus,
      eventstatusColors,
      tableLoading: false,
      tableData: [],
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 180
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width:100
        },
        {
          title: '管理类型',
          dataIndex: 'managetype',
          key: 'managetype',
          ellipsis: true,
          width: 150
        },
        {
          title: '发生时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 150
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 70
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
          width: 70
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          width: 120
        },
        // {
        //   title: '填报时间',
        //   dataIndex: 'writetime',
        //   key: 'writetime',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 180
        // },
        {
          title: '计划性作业',
          dataIndex: 'plannedwork',
          key: 'plannedwork',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'plannedwork' },
          width: 80
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 240
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          align: 'center',
          ellipsis: true,
          width: 220
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          ellipsis: true,
          width: 100
        },
        {
          title: '跟进结果',
          dataIndex: 'followreturn',
          key: 'followreturn',
          ellipsis: true,
          width: 220
        },
        {
          title: '确认人',
          dataIndex: 'confirmman',
          key: 'confirmman',
          ellipsis: true,
          width: 70
        },
        {
          title: '记录人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 200
        },
        {
          title: '完成标志',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstatus' },
          width: 70
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          width: 50,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      alarmModalVisible: false,
      abnormalModalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "跟进处理"
    },
    breadcrumb() {
      const pages = [{name:"项目巡查与报警",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
    this.getTableData()
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
    }
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let param = {
        ...this.queryParams,
        eventstarttime:this.queryParams.eventstarttime?moment(this.queryParams.eventstarttime).format("YYYY-MM-DD"):null,
        eventtype:902,
        dispatcher:this.userInfo.rolename==='项目工程值班员'?1:0,
        rolename:this.userInfo.rolename,
        eventstatus:1,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(param).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.eventcode='';
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      } else if(type == 'edit'){
        this.showModal('edit',record,record)
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        record.eventstarttime=moment(record.eventstarttime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        record.biginfluence=record.biginfluence?record.biginfluence.toString():null
        this.modalDetailData = record;
      }
      this.abnormalModalVisible = true;
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              this.modalShowType='edit'
              record.eventstarttime=moment(record.eventstarttime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
              record.biginfluence=record.biginfluence?record.biginfluence.toString():null
              this.modalDetailData = record;
              this.abnormalModalVisible = true;
            }
          },
        },
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventrecordid);
      }).catch(()=>{
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
  },
}
</script>

<style scoped>

</style>