<template>
  <a-modal v-model="modalVisible" title="选择特别事件" :width="1250" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="特别事件类别" prop="special_event_type" style="width: 35%">
          <a-select v-model="queryParams.special_event_type" style="width: 240px">
            <a-select-option value="治安">治安</a-select-option>
            <a-select-option value="消防">消防</a-select-option>
            <a-select-option value="意外">意外</a-select-option>
            <a-select-option value="跑水">跑水</a-select-option>
            <a-select-option value="能源供应中断">能源供应中断</a-select-option>
            <a-select-option value="设施设备故障">设施设备故障</a-select-option>
            <a-select-option value="卫生">卫生</a-select-option>
            <a-select-option value="信息安全">信息安全</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="特别事件子类别" prop="special_child_type" style="width: 35%">
          <a-select v-model="queryParams.special_child_type" style="width: 240px">
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="群访">群访</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="扰乱秩序">扰乱秩序</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="纠纷">纠纷</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="斗殴">斗殴</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="盗抢">盗抢</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='治安'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='消防'" value="火情">火情</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='消防'" value="电梯困人">电梯困人</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="高空坠落">高空坠落</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="倒塌/塌陷">倒塌/塌陷</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="人员意外受伤">人员意外受伤</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="触电">触电</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="突发疾病">突发疾病</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="车辆/交通">车辆/交通</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="水浸">水浸</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="爆炸">爆炸</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='意外'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='跑水'" value="消防水">消防水</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='跑水'" value="给/排水">给/排水</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='跑水'" value="暖通水">暖通水</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='跑水'" value="生活热水">生活热水</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='跑水'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停电">停电</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="发电">发电</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停水">停水</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="停燃气">停燃气</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='能源供应中断'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="停用电梯">停用电梯</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="停用空调">停用空调</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='设施设备故障'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='卫生'" value="重大传染病">重大传染病</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='卫生'" value="重大不明原因疾病">重大不明原因疾病</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='卫生'" value="中毒">中毒</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='卫生'" value="其它">其它</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="业户信息">业户信息</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="信息化系统">信息化系统</a-select-option>
            <a-select-option v-if="queryParams.special_event_type==='信息安全'" value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="special_event_id" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
        <span slot="start_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="report_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="receive_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="arrive_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import moment from "moment";
import {getTimeRange} from "U/index";
import {getSpecialEventListByCondition} from "A/businessmanagement";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    },
    formData:{
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      monitortypes: [],
      queryInstallDate:null,
      queryParams: {
        monitorpointnum:'',
        monitorpointname:'',
        special_event_num:'',
        accident_level:'',
        special_event_type:'',
        special_child_type:'',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '特别事件报告编号',
          dataIndex: 'special_event_num',
          key: 'special_event_num',
          ellipsis: true,
        },
        {
          title: '生产安全事故等级',
          dataIndex: 'accident_level',
          key: 'accident_level',
          ellipsis: true,
        },
        {
          title: '特别事件类别',
          dataIndex: 'special_event_type',
          key: 'special_event_type',
          ellipsis: true,
        },
        {
          title: '特别事件子类别',
          dataIndex: 'special_child_type',
          key: 'special_child_type',
          ellipsis: true,
        },
        {
          title: '发生时间',
          dataIndex: 'start_time',
          key: 'start_time',
          ellipsis: true,
          scopedSlots: { customRender: 'start_time' },
        },
        {
          title: '报告时间',
          dataIndex: 'report_time',
          key: 'report_time',
          ellipsis: true,
          scopedSlots: { customRender: 'report_time' },
        },
        {
          title: '事发地点',
          dataIndex: 'incident_scene',
          key: 'incident_scene',
          ellipsis: true,
        },
        {
          title: '伤/亡人数',
          dataIndex: 'casualty',
          key: 'casualty',
          ellipsis: true,
        },
        {
          title: '原因类别',
          dataIndex: 'cause_category',
          key: 'cause_category',
          ellipsis: true,
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.special_event_id);
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.planstarttime = start;
      this.queryParams.planendtime = end;
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData();
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
      this.queryInstallDate=null
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getSpecialEventListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.special_event_id) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.special_event_id!=record.special_event_id)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.special_event_id) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.special_event_id!=record.special_event_id)
        })
      }
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>