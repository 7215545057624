<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1200" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'||showType=='patrol'||showType=='record'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" v-model="queryParams.eventnum" style="width: 200px;margin-left: 6px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="系统名称(场景)" prop="systemnameid" style="width: 30%">
          <a-select default-value="" v-model="queryParams.systemnameid" :disabled="showType=='record'?true:false" placeholder="请选择" style="width: 200px;">
            <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报警时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.eventstarttime" @change="calcTimeRange(1)" style="width: 200px;"/>
        </a-form-model-item>
        <a-form-model-item label="报警类型" prop="eventcode" style="width: 30%">
<!--          :disabled="queryParams.confirmedevent==true"-->
          <a-select v-model="queryParams.eventcode" placeholder="请选择" style="width: 200px;">
            <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.systemnameid==7||queryParams.systemnameid==8" label="报警子类型" prop="eventchildcode" style="width: 30%">
          <a-select v-model="queryParams.eventchildcode" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmChildTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="queryParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="queryParams.eventstatus==2">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="queryParams.biginfluence" style="width: 200px;">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.biginfluence!=1&&queryParams.plannedwork!=1" label="特别事件报告编号" prop="specialeventnum" style="width: 30%">
          <a-input v-model="queryParams.specialeventnum" placeholder="" style="width: 100px;" :disabled="queryParams.eventstatus!=2"></a-input>
          <a-button size="small" style="height: 30px;margin-left: 2px" type="primary" @click="selectSpecialModalVisible=true">关联</a-button>
          <a-button size="small" style="height: 30px;margin-left: 2px" type="primary" @click="addSpecialEvent()">新增</a-button>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-popover title="提示">
            <template slot="content">
              <p>正确的编号/位置填写示例："01/03/010；1栋8层南边办公区。"</p>
              <p>即先写编号再写位置，两者之间用“；”分隔。</p>
              <p>若无编号但有名称的，先写名称再写位置。</p>
            </template>
            <a-textarea v-model.trim="queryParams.location" placeholder="请输入" cols="22" rows="2"></a-textarea>
          </a-popover>
        </a-form-model-item>
        <a-form-model-item label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="queryParams.arriveman" placeholder="请输入" style="width: 200px;"></a-input>
        </a-form-model-item>
        <a-form-model-item label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.arrivetime" @change="calcTimeRange(2)" style="width: 200px;"/>
        </a-form-model-item>
        <a-form-model-item label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="queryParams.timerange" addon-after="分钟" placeholder="" style="width: 110px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="到场反馈" prop="feedback" style="width: 30%">
          <a-select v-model="queryParams.feedback" placeholder="请选择" style="width: 200px">
            <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-show="queryParams.systemnameid==1" label="原因分类" prop="causeclassify" style="width: 30%">
          <a-select v-model="queryParams.causeclassify" placeholder="" style="width: 200px">
            <a-select-option v-for="(item, index) in causeClassifyList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <br/>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model="queryParams.description" placeholder="请输入" cols="22" rows="2"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model="queryParams.measure" placeholder="请输入" cols="22" rows="2"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="queryParams.reports" placeholder="请选择" style="width: 200px;">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model.trim="queryParams.repairorder" placeholder="请输入" style="width: 105px;"></a-input>
          <a-button v-if="queryParams.reports!=='无'&&queryParams.reports!==''&&reportsmatter==='使能'" size="small" style="height: 30px" @click="selectModalVisible=true">关联</a-button>
          <a-button v-if="queryParams.reports!=='无'&&queryParams.reports!==''&&reportsmatter==='使能'" size="small" style="height: 30px" type="primary" @click="addMatterOrRepair()">新增</a-button>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="queryParams.recordman" placeholder="" :disabled="true" style="width: 160px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="queryParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 46%">
          <a-textarea v-model="queryParams.followreturn" placeholder="请输入" cols="48" rows="2"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 20%">
          <a-select default-value="" v-model="queryParams.eventstatus" style="width: 85px" @change="calcTimeRange(3)">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 25%">
          <a-input v-model="queryParams.confirmman" placeholder="请输入" :disabled="queryParams.eventstatus==2" style="width: 160px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="queryParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="queryParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal v-model="addModalVisible" title="工单详情" :width="650" :centered="true">
      <template slot="footer">
        <a-button @click="addModalVisible=false">{{'关闭'}}</a-button>
        <a-button  type="primary" @click="confirmRepairs()">{{'提交'}}</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" :model="formData" :rules="formsRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item v-if="queryParams.reports==='报事'" label="报事来源" prop="order_source" style="width: 500px">
          <a-select v-model.trim="formData.order_source">
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修来源" prop="order_source" style="width: 500px">
          <a-select v-model.trim="formData.order_source">
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="接报时间" prop="receive_time" style="width: 500px">
          <a-date-picker v-model.trim="formData.receive_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报事'" label="报事人姓名" prop="contacts" style="width: 500px">
          <a-input v-model.trim="formData.contacts" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报事'" label="报事人电话" prop="contact_information" style="width: 500px">
          <a-input v-model.trim="formData.contact_information" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修人姓名" prop="contacts" style="width: 500px">
          <a-input v-model.trim="formData.contacts" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修人电话" prop="contact_information" style="width: 500px">
          <a-input v-model.trim="formData.contact_information" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报事'" label="报事类型" prop="repair_matter_type" style="width: 500px">
          <a-select v-model.trim="formData.repair_matter_type">
            <a-select-option value="建议">建议</a-select-option>
            <a-select-option value="问询">问询</a-select-option>
            <a-select-option value="质疑">质疑</a-select-option>
            <a-select-option value="求助">求助</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修类型" prop="repair_matter_type" style="width: 500px">
          <a-select v-model.trim="formData.repair_matter_type">
            <a-select-option value="公区维修">公区维修</a-select-option>
            <a-select-option value="专属维修">专属维修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.repair_matter_type==='专属维修'" label="专属维修条目" prop="entry_id" style="width: 500px">
          <a-select v-model="formData.entry_id" style="width: 333px;" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in exclusiveRepair" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <h5 v-if="formData.repair_matter_type==='专属维修'" style="color: red;width: 333px;margin-left: 165px">说明：若无对应专属维修条目，则表示物业服务合同不提供该服务，请联系管家咨询如何处理，谢谢！</h5>
        <a-form-model-item v-if="formData.repair_matter_type==='专属维修'" label="维修服务时间" prop="service_time" style="width: 500px">
          <a-input :disabled="true" style="width: 266px;" v-model="formData.service_time"></a-input><a-button type="primary" @click="makeAppointment">预约</a-button>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 500px">
          <a-select v-model="formData.biginfluence" style="width: 333px;">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="设备名称" prop="device_name" style="width: 500px">
          <a-input v-model.trim="formData.device_name" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="设备编号" prop="device_num" style="width: 500px">
          <a-input v-model.trim="formData.device_num" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="设备位置" prop="device_address" style="width: 500px">
          <a-input v-model.trim="formData.device_address" style="width: 333px;"></a-input>
        </a-form-model-item>
        <!--        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修类别" prop="is_plan_repair" style="width: 500px">-->
        <!--          <a-select :disabled="true" v-model.trim="formData.is_plan_repair">-->
        <!--            <a-select-option value="0">普通维修</a-select-option>-->
        <!--            <a-select-option value="1">计划性维修</a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="计划完成时间" prop="need_finish_time" style="width: 500px">-->
        <!--          <a-date-picker v-model.trim="formData.need_finish_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item v-if="queryParams.reports==='报事'" label="报事内容" prop="content" style="width: 500px">
          <textarea v-model.trim="formData.content" style="width: 333px;"></textarea>
        </a-form-model-item>
        <a-form-model-item v-if="queryParams.reports==='报修'" label="报修内容" prop="content" style="width: 500px">
          <textarea v-model.trim="formData.content" style="width: 333px;"></textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <select-repairs-and-matters :visible.sync="selectModalVisible" @get-selected-rows="correlationRepairs" select-type="radio" :params="selectParams"></select-repairs-and-matters>
    <select-special-event :visible.sync="selectSpecialModalVisible" @get-selected-rows="correlationSpecialEvent" select-type="radio" :params="selectParams"></select-special-event>
    <special-event-modal :visible.sync="specialModalVisible" show-type="add" :detailData="specialModalDetailData" @get-operation-result="updateSpecialEventNum"></special-event-modal>
    <appointment :visible.sync="isAppoint" :detailData="appointDetail" @get-appointment="getAppointment"></appointment>
  </a-modal>
</template>
<script>
import { finishSigns } from "@/json/wlwhistory";
import {getMonitorPointListByCondition, getMonitorPointNameList, getPointParamsList} from "A/monitoring";
import moment from "moment";
import {addEventrecord} from "A/thjlcx";
import {modifyEventrecord} from "A/wlw";
import {mapState} from "vuex";
import {getCache} from "U/index";
import {getEventTypeCodeList} from "A/xtpz";
import SelectSpecialEvent from "C/modals/SelectSpecialEvent.vue";
import SelectRepairsAndMatters from "C/modals/SelectRepairsAndMatters.vue";
import specialEventModal from "V/businessManagement/reportForms/specialEvent/addOrEditModal.vue";
import {addRepairsOrder, getExclusiveRepair, getRepairsOrderInfoByOrderId} from "A/repairsreport";
import appointment from "V/matterAndRepairs/repair/appointment.vue";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  components:{
    appointment,
    SelectRepairsAndMatters,
    specialEventModal,
    SelectSpecialEvent
  },
  data() {
    return {
      alarmTypes:[],
      alarmChildTypes:[],
      finishSigns,
      feedbackList:[],
      systemNames:[],
      bigInfluences:[],
      causeClassifyList:[],
      modalVisible: false,
      reportsmatter: '禁用',
      queryParams:{
        monitorpointid:'',
        eventrecordid:'',
        eventnum:'',
        eventname:'',
        managetype:'',
        biginfluence:"1",
        systemnameid: '',
        eventstarttime:'',
        eventtype:901,
        eventsource:"",
        feedback:'',
        eventcode:'',
        eventchildcode:'',
        monitorpointnum:'',
        location:'',
        description:'',
        measure:'',
        reports:'无',
        repairorder:"无",
        timerange:'',
        followreturn:'',
        confirmman:'',
        writetime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        arriveman:'',
        arrivetime:'',
        recordman:'',
        eventstatus: null,
        confirmtime:'',
        processtime:'',
        plannedwork:0,
        specialeventnum:'待输入',
        confirmedevent:false,
        causeclassify:0,
        scenetypeid:'',
      },
      formRules: {
        systemnameid: [{required: true, message: '请选择系统名称'}],
        eventstarttime: [{required: true, message: '请选择报警时间'}],
        eventcode: [{required: true, message: '请选择报警类型'}],
        location: [{required: true, message: '请输入位置/编码'}],
        arriveman: [{required: true, message: '请输入到达人员'}],
        arrivetime: [{required: true, message: '请输入到达时间'}],
        recordman: [{required: true, message: '请输入记录人'}],
        timerange: [{required: true, message: '请输入确警时长'}],
        feedback: [{required: true, message: '请输入到场反馈'}],
        description: [{required: true, message: '请输入情况描述'}],
        eventstatus: [{required: true, message: '请输入完成标志'}],
        measure: [{required: true, message: '请输入处理措施'}],
        plannedwork: [{required: true, message: '请选择是否为计划性作业'}],
        specialeventnum: [{required: true, message: '请输入特别事件报告编号'}],
        causeclassify: [{required: true, message: '请选择原因分类'}],
      },
      formData: {
        monitorpointnum: '',
        order_num:'',
        order_type:'',
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        need_finish_time: null,
        receive_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status:'',
        create_account: '',
        order_source:'',
        repair_matter_type:'',
        entry_id:'',
        service_time:'',
        biginfluence:'',
        is_plan_repair:'0',
        customer_evaluation:'否',
      },
      //这里面的数据属于必填项
      formsRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        order_type: [{required: true, message: '请选择工单类型'}],
        receive_time: [{required: true, message: '请选择接报时间'}],
        need_finish_time: [{required: true, message: '请选择计划完成的时间'}],
        content: [{required: true, message: '请填写工单具体内容'}],
        contacts: [{required: true, message: '请填写报事联系人'}],
        contact_information: [{required: true, message: '请填写报事联系方式'}],
        order_source: [{required: true, message: '请选择报事/报修来源'}],
        repair_matter_type: [{required: true, message: '请选择报事/报修类型'}],
        entry_id: [{required: true, message: '请选择专属维修条目'}],
        service_time: [{required: true, message: '请选择维修服务时间'}],
        biginfluence: [{required: true, message: '请选择重大影响'}],
        // device_name: [{required: true, message: '请输入设备名称'}],
        // device_address: [{required: true, message: '请输入设备地址'}],
      },
      monitorpointNameList:[],
      addModalVisible: false,
      selectModalVisible: false,
      selectParams: {
        order_type:'',
      },
      specialModalVisible:false,
      specialModalDetailData:{
        monitorpointnum:'',
      },
      selectSpecialModalVisible:false,
      exclusiveRepair:[],

      isAppoint:false,
      appointDetail:{monitorpointnum:''},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '报警跟进'
      }else if(this.showType == 'record') {
        return '报警记录'
      }else if(this.showType=='patrol') {
        return '巡查报警记录'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    'queryParams.systemnameid':function (){
      this.getAlarmTypes()
      if(this.queryParams.systemnameid==1){
        if(!this.queryParams.causeclassify){
          this.queryParams.causeclassify=''
        }
      }else{
        this.queryParams.causeclassify=0
      }
    },
    "queryParams.reports":function (){
      if(this.queryParams.reports==='报事'){
        this.selectParams.order_type='0'
      }else if(this.queryParams.reports==='报修'){
        this.selectParams.order_type='1'
      }
    },
    "formData.order_source":function (val){
      if(val==="自报"){
        this.formData.contacts=this.userInfo.username
        this.formData.contact_information=this.userInfo.phonenum
      }else if(val==="调度中心"){
        this.formData.contacts=this.userInfo.username
        this.formData.contact_information=this.userInfo.phonenum
      }else if(val==="外部报"){
        this.formData.contacts=""
        this.formData.contact_information=""
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    this.getMonitorpointName()
    this.getExclusiveRepairList();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.alarmChildTypes = dictionary.alarmChildTypes;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
      this.causeClassifyList = dictionary.causeClassifyList;
    }
    this.queryParams.recordman = this.userInfo.username
    this.queryParams.confirmman = this.userInfo.username
  },
  methods: {
    getAlarmTypes(){
      getEventTypeCodeList({...this.queryParams}).then(res=>{
        if(res&&res.returncode=='0'){
          this.alarmTypes=res.eventCodeList
          this.alarmChildTypes=res.eventChildCodeList
        }
      })
      getPointParamsList({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length){
          for(let i=0;i<21;i++){
            if(res.item[0][i]!=null){
              this.exclusiveRepairTimes.push({key:i,value:res.item[0][i]})
            }
          }
        }
      })
    },
    getExclusiveRepairList(){
      getExclusiveRepair({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.exclusiveRepair=res.item
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail'||this.showType=='patrol'||this.showType=='record')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if (key == 'writetime') {
              if(this.detailData[key]){
                this.queryParams[key] = moment(this.detailData[key],'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")||'';
              }else{
                this.queryParams[key] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")||'';
              }
            } else if (key == 'plannedwork') {
              if (this.detailData[key]) {
                this.queryParams[key] = this.detailData[key] || '';
              } else {
                this.queryParams[key] = 0
              }
            } else if (key == 'biginfluence') {
              if (this.detailData[key]) {
                this.queryParams[key] = this.detailData[key].toString() || '';
              } else {
                this.queryParams[key] = "1"
              }
            } else if (key == 'repairorder') {
              if (this.detailData[key]) {
                this.queryParams[key] = this.detailData[key] || '';
              } else {
                this.queryParams[key] = "无"
              }
            } else if (key == 'eventcode') {
              if (this.detailData[key]) {
                if(this.detailData[key]==="0998"){
                  this.queryParams[key] = ''
                }else{
                  this.queryParams[key] = this.detailData[key] || '';
                }
              } else {
                this.queryParams[key] = ''
              }
            } else if (key == 'eventchildcode') {
              if (this.detailData[key]) {
                this.queryParams[key] = this.detailData[key] || '';
              } else {
                this.queryParams[key] = ''
              }
            } else if (key == 'causeclassify') {
              if (this.detailData[key]) {
                this.queryParams[key] = this.detailData[key].toString() || '';
              } else {
                this.queryParams[key] = ''
              }
            } else if (key == 'confirmman') {
              this.queryParams.confirmman = this.userInfo.username
            } else {
              this.queryParams[key] = this.detailData[key] || '';
            }
          }
        })
      }
    },
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item.length>0?res.item[0].monitorpointnum:""
          getMonitorPointListByCondition({monitorpointnum:res.item[0].monitorpointnum}).then(res=>{
            if(res && res.returncode == '0'){
              this.reportsmatter=res.item[0].reportsmatter
            }
          })
        }
      })
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit'||this.showType=='patrol'||this.showType=='record') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.eventstarttime = moment(this.queryParams.eventstarttime).format("YYYYMMDDHHmmss")
            this.queryParams.arrivetime = moment(this.queryParams.arrivetime).format("YYYYMMDDHHmmss")
            this.queryParams.confirmtime = this.queryParams.confirmtime?moment(this.queryParams.confirmtime).format("YYYYMMDDHHmmss"):null
            this.queryParams.writetime = moment(this.queryParams.writetime).format("YYYYMMDDHHmmss")
            this.queryParams.eventsource=this.userInfo.rolename==='项目工程值班员'?"工程巡查（系统）":"平台巡查（系统）"
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add' || this.showType == 'patrol') {
              addEventrecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyEventrecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    calcTimeRange(type){
      let eventstarttime=Date.parse(new Date(moment(this.queryParams.eventstarttime).format("YYYY-MM-DD HH:mm:ss")))/1000
      let arrivetime=Date.parse(new Date(moment(this.queryParams.arrivetime).format("YYYY-MM-DD HH:mm:ss")))/1000
      if(((arrivetime-eventstarttime)/60).toFixed(2)>0){
        this.queryParams.timerange=((arrivetime-eventstarttime)/60).toFixed(2)
      }else if(((arrivetime-eventstarttime)/60).toFixed(2)<0){
        this.queryParams.arrivetime=null
        this.queryParams.timerange=null
        this.$message.error("“报警时间”录入有误请复核")
      }
      if(this.queryParams.eventstatus==2){
        if(this.queryParams.specialeventnum=="待输入"&&this.queryParams.biginfluence!=1&&this.queryParams.plannedwork!=1){
          this.queryParams.specialeventnum=""
        }else if(this.queryParams.specialeventnum!=""){

        }else{
          this.queryParams.specialeventnum="待输入"
        }
        this.queryParams.confirmtime=type==3?moment(new Date()).format("YYYY-MM-DD HH:mm:ss"):this.queryParams.confirmtime
        let confirmtime=Date.parse(new Date(moment(this.queryParams.confirmtime).format("YYYY-MM-DD HH:mm:ss")))/1000
        if(!arrivetime){
          this.queryParams.eventstatus=''
          this.queryParams.confirmtime=null
          this.queryParams.processtime=null
          this.$message.error("请先填写“到场时间”")
        }else if(((confirmtime-eventstarttime)/60).toFixed(2)>0&&((confirmtime-arrivetime)/60).toFixed(2)>0){
          this.queryParams.processtime=((confirmtime-eventstarttime)/60).toFixed(2)
        }else if(((confirmtime-eventstarttime)/60).toFixed(2)<0){
          this.queryParams.eventstatus=''
          this.queryParams.confirmtime=null
          this.queryParams.processtime=null
          this.$message.error("“报警时间”录入有误请复核")
        }else if(((confirmtime-arrivetime)/60).toFixed(2)<0){
          this.queryParams.eventstatus=''
          this.queryParams.confirmtime=null
          this.queryParams.processtime=null
          this.$message.error("“到场时间”录入有误请复核")
        }
      }else{
        this.queryParams.processtime=""
        this.queryParams.confirmtime=null
        if(!this.queryParams.specialeventnum){
          this.queryParams.specialeventnum="待输入"
        }
      }
    },
    correlationSpecialEvent(selectedRows){
      this.queryParams.special_event_id=selectedRows[0].special_event_id
      this.queryParams.specialeventnum=selectedRows[0].special_event_num
    },
    addSpecialEvent(){
      this.specialModalDetailData.monitorpointnum=this.queryParams.monitorpointnum
      this.specialModalVisible=true
    },
    updateSpecialEventNum(e){
      this.queryParams.specialeventnum=e
    },
    correlationRepairs(selectedRows){
      this.queryParams.order_id=selectedRows[0].order_id
      this.queryParams.repairorder=selectedRows[0].order_num
    },
    confirmRepairs() {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.formData.receive_time = moment(this.formData.receive_time).format("YYYYMMDDHHmmss")
          this.formData.process_node = 10
          this.formData.order_type = this.queryParams.reports==="报修"?"1":"0"
          let params = {
            ...this.formData,
          };
          this.showLoading();
          addRepairsOrder(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              this.$message.success('操作成功');
              this.queryParams.order_id = res.order_id
              getRepairsOrderInfoByOrderId({order_id:res.order_id}).then(res=>{
                if(res&&res.returncode==='0'){
                  this.queryParams.repairorder=res.item.order_num
                }
              })
              this.$emit('get-operation-result', 'success', '操作成功');
              this.addModalVisible = false;
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        } else {
          this.$message.warning('请确认信息填写完整')
          return false;
        }
      })
    },
    addMatterOrRepair(){
      this.formData={
        monitorpointnum: '',
        order_num:'',
        order_type:'',
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        need_finish_time: null,
        receive_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status:'',
        create_account: '',
        order_source:'自报',
        repair_matter_type:'',
        is_trapped:'',
        biginfluence:'',
        is_plan_repair:'0',
        customer_evaluation:'否',
      }
      this.formData.monitorpointnum=this.queryParams.monitorpointnum
      this.formData.contacts=this.userInfo.username
      this.formData.contact_information=this.userInfo.phonenum
      this.formData.receive_time=this.queryParams.writetime
      this.formData.biginfluence=this.queryParams.biginfluence
      this.formData.content=this.queryParams.description
      this.addModalVisible=true
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    makeAppointment(){
      this.isAppoint=true
      this.appointDetail.monitorpointnum=this.formData.monitorpointnum
    },
    getAppointment(val){
      this.formData.service_time=val
    }
  }
}
</script>