<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="650" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 500px">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择项目" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事来源" prop="order_source" style="width: 500px">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.order_source">
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="接报时间" prop="receive_time" style="width: 500px">
          <a-date-picker :disabled="showType=='detail'" v-model.trim="formData.receive_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="报事人姓名" prop="contacts" style="width: 500px">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.contacts" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item label="报事人电话" prop="contact_information" style="width: 500px">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.contact_information" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item label="报事类型" prop="repair_matter_type" style="width: 500px">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.repair_matter_type">
            <a-select-option value="建议">建议</a-select-option>
            <a-select-option value="问询">问询</a-select-option>
            <a-select-option value="质疑">质疑</a-select-option>
            <a-select-option value="求助">求助</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 500px">
          <a-select v-model="formData.biginfluence" style="width: 333px;">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="是否延期" prop="is_postpone" style="width: 500px">-->
<!--          <a-select :disabled="showType=='detail'" v-model.trim="formData.is_postpone">-->
<!--            <a-select-option value="是">是</a-select-option>-->
<!--            <a-select-option value="否">否</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="是否需要客户评价" prop="customer_evaluation" style="width: 500px">-->
<!--          <a-select :disabled="showType=='detail'" v-model.trim="formData.customer_evaluation">-->
<!--            <a-select-option value="是">是</a-select-option>-->
<!--            <a-select-option value="否">否</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="计划完成时间" prop="need_finish_time" style="width: 500px">-->
<!--          <a-date-picker :disabled="showType=='detail'" v-model.trim="formData.need_finish_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="报事内容" prop="content" style="width: 500px">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.content" style="width: 333px;"></textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getCache} from "U/index";
import {
  addRepairsOrder,
  getRepairsOrderListByCondition,
  modifyRepairsOrder
} from "A/repairsreport";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '600px',
        overflowY: 'auto',
      },
      modalVisible: false,
      bigInfluences:[],
      formData: {
        monitorpointnum: '',
        order_num:'',
        order_type:'0',
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        biginfluence: "1",
        need_finish_time: null,
        receive_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status:'',
        create_account: '',
        order_source:'自报',
        repair_matter_type:'',
        is_postpone:'',
        is_plan_repair:'0',
        customer_evaluation:'否',
        whether_evaluate:'否',
        whether_check:'否',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        order_type: [{required: true, message: '请选择工单类型'}],
        receive_time: [{required: true, message: '请选择接报时间'}],
        need_finish_time: [{required: true, message: '请选择计划完成的时间'}],
        content: [{required: true, message: '请填写工单具体内容'}],
        contacts: [{required: true, message: '请填写报事联系人'}],
        contact_information: [{required: true, message: '请填写报事联系方式'}],
        order_source: [{required: true, message: '请选择报事来源'}],
        repair_matter_type: [{required: true, message: '请选择报事类型'}],
        // is_postpone: [{required: true, message: '请选择是否延期'}],
        //customer_evaluation: [{required: true, message: '请选择是否需要客户评价'}],
      },
      monitorpointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    "formData.order_source":function (val){
      if(val==="自报"){
        this.formData.contacts=this.userInfo.username
        this.formData.contact_information=this.userInfo.phonenum
      }else if(val==="调度中心"){
        this.formData.contacts=this.userInfo.username
        this.formData.contact_information=this.userInfo.phonenum
      }else if(val==="外部报"){
        this.formData.contacts=""
        this.formData.contact_information=""
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
    }
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.order_id) {
        if(this.showType == 'edit' || this.showType == 'detail') {
          this.$nextTick(() => {
            let params = {
              order_id: this.detailData.order_id
            }
            getRepairsOrderListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                this.formData.need_finish_time=moment(res.item[0].need_finish_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                this.formData.receive_time=moment(res.item[0].receive_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
              }
            })
          })
        }
      }else{
        this.formData.receive_time=moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        this.formData.contacts=this.userInfo.username
        this.formData.contact_information=this.userInfo.phonenum
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.need_finish_time = this.formData.need_finish_time?moment(this.formData.need_finish_time).format("YYYYMMDDHHmmss"):null
            this.formData.receive_time = this.formData.receive_time?moment(this.formData.receive_time).format("YYYYMMDDHHmmss"):null
            this.formData.process_node=10
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addRepairsOrder(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyRepairsOrder(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>