<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1260" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <span style="color: red;margin-left: 100px;font-size: large">说明：带“*”是必填项，记录创建时必须填写，其余信息可以在调查完成后，通过修改的方式补填完整</span>
      <a-button v-if="(detailData&&detailData.enable)||(showType==='detail'&&btnList.includes('导出'))" type="primary" style="float: right"  @click="exportSpecialEventWord()">导出</a-button>
      <a-divider></a-divider>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption" @change="getSpecialEventNums()">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="特别事件报告编号" prop="special_event_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.special_event_num" />
        </a-form-model-item>
        <a-form-model-item label="生产安全事故等级" prop="accident_level">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.accident_level">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="一般事故">一般事故</a-select-option>
            <a-select-option value="较大事故">较大事故</a-select-option>
            <a-select-option value="重大事故">重大事故</a-select-option>
            <a-select-option value="特别重大事故">特别重大事故</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="特别事件类别" prop="special_event_type">
          <a-select :disabled="showType=='detail'" v-model="formData.special_event_type">
            <a-select-option value="治安">治安</a-select-option>
            <a-select-option value="消防">消防</a-select-option>
            <a-select-option value="意外">意外</a-select-option>
            <a-select-option value="跑水">跑水</a-select-option>
            <a-select-option value="能源供应中断">能源供应中断</a-select-option>
            <a-select-option value="设施设备故障">设施设备故障</a-select-option>
            <a-select-option value="卫生">卫生</a-select-option>
            <a-select-option value="信息安全">信息安全</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="特别事件子类别" prop="special_child_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.special_child_type">
            <a-select-option v-if="formData.special_event_type==='治安'" value="群访">群访</a-select-option>
            <a-select-option v-if="formData.special_event_type==='治安'" value="扰乱秩序">扰乱秩序</a-select-option>
            <a-select-option v-if="formData.special_event_type==='治安'" value="纠纷">纠纷</a-select-option>
            <a-select-option v-if="formData.special_event_type==='治安'" value="斗殴">斗殴</a-select-option>
            <a-select-option v-if="formData.special_event_type==='治安'" value="盗抢">盗抢</a-select-option>
            <a-select-option v-if="formData.special_event_type==='治安'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='消防'" value="火情">火情</a-select-option>
            <a-select-option v-if="formData.special_event_type==='消防'" value="电梯困人">电梯困人</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="高空坠落">高空坠落</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="倒塌/塌陷">倒塌/塌陷</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="人员意外受伤">人员意外受伤</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="触电">触电</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="突发疾病">突发疾病</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="车辆/交通">车辆/交通</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="水浸">水浸</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="爆炸">爆炸</a-select-option>
            <a-select-option v-if="formData.special_event_type==='意外'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='跑水'" value="消防水">消防水</a-select-option>
            <a-select-option v-if="formData.special_event_type==='跑水'" value="给/排水">给/排水</a-select-option>
            <a-select-option v-if="formData.special_event_type==='跑水'" value="暖通水">暖通水</a-select-option>
            <a-select-option v-if="formData.special_event_type==='跑水'" value="生活热水">生活热水</a-select-option>
            <a-select-option v-if="formData.special_event_type==='跑水'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='能源供应中断'" value="停电">停电</a-select-option>
            <a-select-option v-if="formData.special_event_type==='能源供应中断'" value="发电">发电</a-select-option>
            <a-select-option v-if="formData.special_event_type==='能源供应中断'" value="停水">停水</a-select-option>
            <a-select-option v-if="formData.special_event_type==='能源供应中断'" value="停燃气">停燃气</a-select-option>
            <a-select-option v-if="formData.special_event_type==='能源供应中断'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='设施设备故障'" value="停用电梯">停用电梯</a-select-option>
            <a-select-option v-if="formData.special_event_type==='设施设备故障'" value="停用空调">停用空调</a-select-option>
            <a-select-option v-if="formData.special_event_type==='设施设备故障'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='卫生'" value="重大传染病">重大传染病</a-select-option>
            <a-select-option v-if="formData.special_event_type==='卫生'" value="重大不明原因疾病">重大不明原因疾病</a-select-option>
            <a-select-option v-if="formData.special_event_type==='卫生'" value="中毒">中毒</a-select-option>
            <a-select-option v-if="formData.special_event_type==='卫生'" value="其它">其它</a-select-option>
            <a-select-option v-if="formData.special_event_type==='信息安全'" value="业户信息">业户信息</a-select-option>
            <a-select-option v-if="formData.special_event_type==='信息安全'" value="信息化系统">信息化系统</a-select-option>
            <a-select-option v-if="formData.special_event_type==='信息安全'" value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发生时间" prop="start_time">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.start_time" show-time style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="报告时间" prop="report_time">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.report_time" show-time style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="接报时间" prop="receive_time">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.receive_time" show-time style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="到场时间" prop="arrive_time">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.arrive_time" show-time style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="事发地点" prop="incident_scene">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.incident_scene" />
        </a-form-model-item>
        <a-form-model-item label="原因类别" prop="cause_category">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.cause_category">
            <a-select-option value="业户">业户</a-select-option>
            <a-select-option value="外来人员">外来人员</a-select-option>
            <a-select-option value="外委人员">外委人员</a-select-option>
            <a-select-option value="员工">员工</a-select-option>
            <a-select-option value="能源供应单位">能源供应单位</a-select-option>
            <a-select-option value="设施设备">设施设备</a-select-option>
            <a-select-option value="台风">台风</a-select-option>
            <a-select-option value="暴雨">暴雨</a-select-option>
            <a-select-option value="地震">地震</a-select-option>
            <a-select-option value="雷电">雷电</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="伤/亡人数" prop="casualty">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.casualty" />
        </a-form-model-item>
        <a-form-model-item label="报告人" prop="reporter">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.reporter" />
        </a-form-model-item>
        <a-form-model-item label="到场人员" prop="arrive_man">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.arrive_man" />
        </a-form-model-item>
        <a-form-model-item label="事情经过" prop="whole_story">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.whole_story" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="采取的行动" prop="take_steps">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.take_steps" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="直接经济损失" prop="damage">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.damage" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="预防措施" prop="prevent_measure">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.prevent_measure" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="结论" prop="conclusion">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.conclusion" style="width: 390px"/>
        </a-form-model-item>
        <a-divider></a-divider>
        <a-form-model-item label="事件现场图片1" prop="pic_url">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList1"
              @preview="handlePreview(formData.pic_url)"
              @change="picUpload">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图片描述1" prop="describe1">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.describe1" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="事件现场图片2" prop="pic_url2">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList2"
              @preview="handlePreview(formData.pic_url2)"
              @change="picUpload2">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图片描述2" prop="describe2">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.describe2" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="事件现场图片3" prop="pic_url3">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList3"
              @preview="handlePreview(formData.pic_url3)"
              @change="picUpload3">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图片描述3" prop="describe3">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.describe3" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="事件现场图片4" prop="pic_url4">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList4"
              @preview="handlePreview(formData.pic_url4)"
              @change="picUpload4">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图片描述4" prop="describe4">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.describe4" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="事件现场图片5" prop="pic_url5">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList5"
              @preview="handlePreview(formData.pic_url5)"
              @change="picUpload5">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图片描述5" prop="describe5">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.describe5" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  getMonitorPointNameList,
  getMonitorPointNameListByCondition
} from "A/monitoring";
import {
  addSpecialEvent, exportSpecialEventWord,
  getSpecialEventListByCondition, getSpecialEventNums,
  modifySpecialEvent
} from "A/businessmanagement";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '660px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum:'',
        special_event_num:'',
        accident_level:'',
        special_event_type:'',
        special_child_type:'',
        start_time:null,
        report_time:null,
        receive_time:null,
        arrive_time:null,
        incident_scene:'',
        casualty:'',
        reporter:'',
        arrive_man:'',
        whole_story:'',
        damage:'',
        prevent_measure:'',
        take_steps:'',
        cause_category:'',
        conclusion:'',
        pic_url:'',
        describe1:'',
        pic_url2:'',
        describe2:'',
        pic_url3:'',
        describe3:'',
        pic_url4:'',
        describe4:'',
        pic_url5:'',
        describe5:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        special_event_num: [{required: true, message: '请填写特别事件报告编号'}],
        // accident_level: [{required: true, message: '请选择生产安全事故等级'}],
        // special_event_type: [{required: true, message: '请选择特别事件类别'}],
        // special_child_type: [{required: true, message: '请选择特别事件子类别'}],
        // start_time: [{required: true, message: '请选择发生时间'}],
      },
      monitorpointList:[],
      baseurl:'https://fda.u-lake.com/',
      picList1: [],
      picList2: [],
      picList3: [],
      picList4: [],
      picList5: [],
      previewImage: '',
      previewVisible:false,

      //操作按钮权限
      btnList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBusinessManagement']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        if(this.detailData){
          this.btnList=this.detailData.btnList
        }
        this.resetData();
        this.getMonitorpointName();
        // this.getMonitorPointNameList();
        // this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorpointName() {
      getMonitorPointNameList({userdepid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList=res.item
          this.formData.monitorpointnum = res.item[0].monitorpointnum
          this.initDetail();
          if(this.showType == 'add'){
            this.getSpecialEventNums()
          }
        }
      })
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.picList1=[]
      this.picList2=[]
      this.picList3=[]
      this.picList4=[]
      this.picList5=[]
      this.queryInstallDate = []
    },
    initDetail() {
      if (this.detailData && this.detailData.special_event_id) {
        if(this.showType == 'edit' || this.showType == 'detail') {
          this.$nextTick(() => {
            let params = {
              special_event_id: this.detailData.special_event_id
            }
            getSpecialEventListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                this.formData.start_time=res.item[0].start_time?moment(res.item[0].start_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                this.formData.report_time=res.item[0].report_time?moment(res.item[0].report_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                this.formData.receive_time=res.item[0].receive_time?moment(res.item[0].receive_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                this.formData.arrive_time=res.item[0].arrive_time?moment(res.item[0].arrive_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                if (this.formData.pic_url) {
                  this.previewImage=this.baseurl+this.formData.pic_url
                  let nameIndex = this.formData.pic_url.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url.substr(nameIndex + 1);
                  this.picList1 = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url,
                  }];
                }
                if (this.formData.pic_url2) {
                  this.previewImage=this.baseurl+this.formData.pic_url2
                  let nameIndex = this.formData.pic_url2.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url2.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url2.substr(nameIndex + 1);
                  this.picList2 = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url2,
                  }];
                }
                if (this.formData.pic_url3) {
                  this.previewImage=this.baseurl+this.formData.pic_url3
                  let nameIndex = this.formData.pic_url3.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url3.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url3.substr(nameIndex + 1);
                  this.picList3 = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url3,
                  }];
                }
                if (this.formData.pic_url4) {
                  this.previewImage=this.baseurl+this.formData.pic_url4
                  let nameIndex = this.formData.pic_url4.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url4.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url4.substr(nameIndex + 1);
                  this.picList4 = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url4,
                  }];
                }
                if (this.formData.pic_url5) {
                  this.previewImage=this.baseurl+this.formData.pic_url5
                  let nameIndex = this.formData.pic_url5.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url5.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url5.substr(nameIndex + 1);
                  this.picList5 = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url5,
                  }];
                }
              }
            })
          })
        }
      }
    },
    getSpecialEventNums(){
      getSpecialEventNums({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode==='0'){
          this.formData.special_event_num=res.special_event_num
        }
      })
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.start_time=this.formData.start_time?moment(this.formData.start_time).format("YYYYMMDDHHmmss"):null
            this.formData.report_time=this.formData.report_time?moment(this.formData.report_time).format("YYYYMMDDHHmmss"):null
            this.formData.receive_time=this.formData.receive_time?moment(this.formData.receive_time).format("YYYYMMDDHHmmss"):null
            this.formData.arrive_time=this.formData.arrive_time?moment(this.formData.arrive_time).format("YYYYMMDDHHmmss"):null
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addSpecialEvent(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', this.formData.special_event_num);
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifySpecialEvent(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    picUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url = file.response.urlPath;
        }
        return file;
      });
      this.picList1 = fileList;
    },
    picUpload2(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url2 = file.response.urlPath;
        }
        return file;
      });
      this.picList2 = fileList;
    },
    picUpload3(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url3 = file.response.urlPath;
        }
        return file;
      });
      this.picList3 = fileList;
    },
    picUpload4(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url4 = file.response.urlPath;
        }
        return file;
      });
      this.picList4 = fileList;
    },
    picUpload5(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url5 = file.response.urlPath;
        }
        return file;
      });
      this.picList5 = fileList;
    },
    async handlePreview(url) {
      this.previewImage = this.baseurl + url;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    exportSpecialEventWord(){
      let params={
        special_event_id:this.detailData.special_event_id
      }
      exportSpecialEventWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>