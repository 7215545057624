<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="报警跟进处理">
            <alarm></alarm>
          </a-tab-pane>
          <a-tab-pane key="2" tab="异常跟进处理">
            <abnormal></abnormal>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </page-container>
</template>

<script>
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import alarm from "V/patrols/patrolsRecord/followUp/alarm";
import abnormal from "V/patrols/patrolsRecord/followUp/abnormal";

export default {
  name: "followUp",
  mixins: [pagination],
  components:{
    alarm,
    abnormal,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "跟进处理"
    },
    breadcrumb() {
      const pages = [{name:"项目巡查与报警",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
  },
  methods:{
  },
}
</script>

<style scoped>

</style>