import store from '@/store'
import http from '@/utils/http'
// import qs from 'qs'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
    preApi = '/mock';
}

// 查询通话记录
export function getCalllog(params) {
    return http({
        url: `${preApi}/thirdpart/getCalllog`,
        method: "post",
        data: params
    })
}

//添加事件
export function addEventrecord(params) {
    return http({
        url: `${preApi}/event/addEventrecord`,
        method: "post",
        data: params
    })
}

//添加救援记录
export function addAlarmFiles(params) {
    return http({
        url: `${preApi}/lift/addAlarmFiles`,
        method: "post",
        data: params
    })
}

//删除救援记录
export function deleteAlarmFiles(params) {
    return http({
        url: `${preApi}/lift/deleteAlarmFiles`,
        method: "post",
        data: params
    })
}

//更新救援记录
export function modifyAlarmFiles(params) {
    return http({
        url: `${preApi}/lift/modifyAlarmFiles`,
        method: "post",
        data: params
    })
}

// 查询救援记录
export function getAlarmFilesListByCondition(params) {
    return http({
        url: `${preApi}/lift/getAlarmFilesListByCondition`,
        method: "post",
        data: params
    })
}

// 添加未处理来电
export function addUntreatedCall(params) {
    return http({
        url: `${preApi}/thirdpart/addUntreatedCall`,
        method: "post",
        data: params
    })
}

// 删除未处理来电
export function deleteUntreatedCall(params) {
    return http({
        url: `${preApi}/thirdpart/deleteUntreatedCall`,
        method: "post",
        data: params
    })
}

// 查询未处理来电
export function getUntreatedCallListByCondition(params) {
    return http({
        url: `${preApi}/thirdpart/getUntreatedCallListByCondition`,
        method: "post",
        data: params
    })
}

// 发送救援短信
export function sendMessage(params) {
    return http({
        url: `${preApi}/thirdpart/sendMessage`,
        method: "post",
        data: params
    })
}

//添加投诉建议记录
export function addAlarmessage(params) {
    return http({
        url: `${preApi}/lift/addAlarmessage`,
        method: "post",
        data: params
    })
}

// 查询投诉建议记录
export function deleteAlarmessage(params) {
    return http({
        url: `${preApi}/lift/deleteAlarmessage`,
        method: "post",
        data: params
    })
}

// 查询投诉建议记录
export function getAlarmessageListByCondition(params) {
    return http({
        url: `${preApi}/lift/getAlarmessageListByCondition`,
        method: "post",
        data: params
    })
}

//查看故障图片
export function viewUserImg(params){
    return http({
        url: `${preApi}/thirdpart/viewImg`,
        method: "post",
        data: params,
        // responseType:"arraybuffer",
    })
}

export function viewUserLiftImg(params){
    return http({
        url: `${preApi}/thirdpart/viewLiftImg`,
        method: "post",
        data: params,
        // responseType:"arraybuffer",
    })
}

//查看APP救援图片
export function findRescuepicByAlarmId(params){
    return http({
        url: `${preApi}/lift/findRescuepicByAlarmId`,
        method: "post",
        data: params,
    })
}

//连接master控制端
export function master_link(params){
    return http({
        url: `${preApi}/thirdpart/master_link`,
        method: "post",
        data: params,
    })
}